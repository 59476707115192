import React, { Component } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import CaseCard from '../Components/CaseCard/CaseCard';
import Paginator from '../Components/Paginator/Paginator';
import LottieControl from '../Components/Lotties/Empty';
import API from "../utils/api";
import ENDPOINTS from "../utils/endpoints";
import PropTypes from 'prop-types';

let _timer = null;

class Home extends Component {
  constructor(props) {
    super(props);
    this.userId = props.thisUser.sub;
    this.token = props.thisUser.token;
    this.state = {
      activeTab: '1',
      limit: null,
      activeCases: [],
      inactiveCases: [],
      activePage: 1,
      inactivePage: 1,
      activeTotalPages: null,
      inactiveTotalPages: null,
      activeCount: 0,
      inactiveCount: 0,
      searchQuery: "",
      lastQuery: ""
    };
    this.getTotalsCount = this.getTotalsCount.bind(this);
    this.getCases = this.getCases.bind(this);
  }

  _setState = (state, state_validation) => {
    this.setState(state, ()=>{
      this.getCases(state_validation);
    });
  }

  componentWillMount () {
    this.getTotalsCount();
    this.getCases(true);
    this.getCases(false);
  }

  toggle(tab) {
    let lastQuery = this.state.lastQuery;
    let searchQuery = this.state.searchQuery;
    this.setState({
      activeTab:tab,
      searchQuery: lastQuery ? lastQuery : "",
      lastQuery: searchQuery ? searchQuery : ""
    });
  }

  getTotalsCount() {
    API(this.token).post(ENDPOINTS.getCasesCount, {
      user_id: this.userId
    }).then(res => {
      let activeCount = 0;
      let inactiveCount = 0;
      const data = res.data;

      if(data.response.length > 1) {
        activeCount = data.response[0].status === "active" ? data.response[0].count : data.response[1].count;
        inactiveCount = data.response[0].status === "inactive" ? data.response[0].count : data.response[1].count;
      } else if (res.data.response.length === 1) {
        activeCount = data.response[0].status === "active" ? data.response[0].count : 0;
        inactiveCount = data.response[0].status === "inactive" ? data.response[0].count : 0;
      }

      this.setState({
        activeCount: activeCount,
        inactiveCount: inactiveCount
      });
    })
  }

  handleChange(event) {
    this.setState({
      "searchQuery": event.target.value
    }, () => {
      let active_bool = this.state.activeTab === '1';

      _timer = _timer ? clearTimeout(_timer) : null;
      _timer = setTimeout(()=>{
        this.getCases(active_bool);
      }, 1e3);
    });
  }

  getCases(active) {
    let current_state = this.state;
    let body = {
      active: active,
      user_id: this.userId
    }

    if ((current_state.activeTotalPages || current_state.inactiveTotalPages) && current_state.limit) {
      body["page"] = active ? current_state.activePage : current_state.inactivePage;
      body["limit"] = current_state.limit;
    }

    if (current_state.searchQuery) {
      body["text"] = current_state.searchQuery;
    }

    API(this.token).post(ENDPOINTS.getCases, body).then(res => {
      if (active) {
        this.setState( {
          activeCases: res.data.cases,
          activeTotalPages: res.data.total_pages,
          limit: res.data.limit,
          activePage: res.data.page
        } );
      }
      else {
        this.setState({
          inactiveCases: res.data.cases,
          inactiveTotalPages: res.data.total_pages,
          limit: res.data.limit,
          inactivePage: res.data.page
        });
      }
    }).catch(error => console.error(error));
  }

  render() {
    return (
      <div className="container min-h">
        <div className="row mt-xxl">
          <div className="col-12">
            <p className="h1-b mb-0">Bienvenido</p>
          </div>
        </div>

        <div className="row my-l">
          <div className="col-12 col-lg-3">
            <a href="/new-case"><button className="button -primary w-100"><i className="fas fa-plus"></i> Nuevo caso</button></a>
          </div>
        </div>
        { this.state.activeCases.length > 0 || this.state.inactiveCases.length > 0 ?
        <div className="row">
          <div className="col-12">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '1' })}
                  onClick={() => { this.toggle('1'); }}
                >
                  CASOS ACTIVOS
                  <label className="casenumber">{this.state.activeCount}</label>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.toggle('2'); }}
                >
                  CASOS INACTIVOS
                  <label className="casenumber">{this.state.inactiveCount}</label>
                </NavLink>
              </NavItem>
            </Nav>
            <div className="row my-l">
              <div className="col-12 col-lg-8">
                <div className="input-group -search">
                  <input
                    className="-search" type="search" value={this.state.searchQuery}
                    onChange={this.handleChange.bind(this)} placeholder="Buscador ej. Renta mensual, Pago de servicios"
                  />
                  <div className="input-icon"><i className="fas fa-search"></i></div>
                </div>
              </div>
            </div>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                { this.state.activeCases.length > 0 ?
                  <div className="row">
                    {this.state.activeCases.map((_case, index) => {
                      return (
                        <div key={`active_card_${index}`} className="col-12 col-lg-8 mb-m">
                          <CaseCard
                            caseId={_case._id}
                            userId={this.userId}
                            caseName={_case.concept}
                            creationDate={_case.created_at}
                            businessName={_case.company}
                            amount={_case.amount}
                            responsable={`${_case.responsible.aas_name} ${_case.responsible.aas_lastname}`}
                            caseType={_case.case_name}
                            frecuency={_case.frequency}
                            comments={_case.comments}
                            state="deactivate"
                            getCases={this.getCases}
                            getCasesCount={this.getTotalsCount}
                            token={this.token}
                            lastExecution={_case.last_execution}
                          />
                        </div>
                      )
                    })}
                  </div>
                  :  <LottieControl /> } {/* TODO: Change Lottie */}
                { this.state.activeTotalPages > 1 &&
                  <div className="row">
                    <div className="col-12 col-lg-8 d-flex justify-content-center">
                      <Paginator
                        pages={ this.state.activeTotalPages }
                        setState={ this._setState }
                        currentPage={ this.state.activePage }
                        stateName="active"
                        getCases={ this.getCases }
                      />
                    </div>
                  </div>
                }
              </TabPane>
              <TabPane tabId="2">
                { this.state.inactiveCases.length > 0 ?
                  <div className="row">
                    {this.state.inactiveCases.map((_case, index) => {
                      return (
                        <div key={`active_card_${index}`} className="col-12 col-lg-8 mb-m">
                          <CaseCard
                            caseId={_case._id}
                            userId={this.userId}
                            caseName={_case.concept}
                            creationDate={_case.created_at}
                            businessName={_case.company}
                            amount={_case.amount}
                            responsable={`${_case.responsible.aas_name} ${_case.responsible.aas_lastname}`}
                            caseType={_case.case_name}
                            frecuency={_case.frequency}
                            comments={_case.comments}
                            state="activate"
                            getCases={this.getCases}
                            getCasesCount={this.getTotalsCount}
                            token={this.token}
                            lastExecution={_case.last_execution}
                          />
                        </div>
                      )
                    })}
                  </div>
                : <LottieControl />

                }
                {/* TODO: Change Lottie */}
                { this.state.inactiveTotalPages > 1 &&
                  <div className="row">
                    <div className="col-12 col-lg-8 d-flex justify-content-center">
                      <Paginator
                        pages={ this.state.inactiveTotalPages }
                        setState={ this._setState }
                        currentPage={ this.state.inactivePage }
                        stateName="inactive"
                        getCases={ this.getCases }
                      />
                    </div>
                  </div>
                }
              </TabPane>
            </TabContent>
          </div>
        </div>
      : <LottieControl /> }
      </div>
    );
  }
}

Home.propTypes = {
  thisUser: PropTypes.object
};

export default Home;