import React from 'react';
import { Router, Switch } from 'react-router-dom';
// Styles imports
import './App.scss';
// Pages imports
import Home from './Pages/Home';
import NewCase from './Pages/NewCase';
import ManageUsers from './Pages/ManageUsers';
import NewUser from './Pages/NewUser';
import EditUser from './Pages/EditUser'
// Principal components imports
import NavbarComponent from './Components/NavbarComponent/NavbarComponent';
import Footer from './Components/Footer/Footer';
import LoadingScreen from "./Components/Lotties/Loading";
// Auth 0 imports
import PrivateRoute from "./Components/PrivateRoute";
import { useAuth0 } from "./react-auth0-spa";
// Utilities imports
import history from "./utils/history";


const App = () => {
  const { loading, user, isAuthenticated } = useAuth0();

  if (loading) {
    return <LoadingScreen />;
  }

  if (isAuthenticated) {
    window.localStorage.setItem("roles", JSON.stringify(user.roles));
    window.localStorage.setItem("token", user.token);
  } else {
    window.localStorage.removeItem("roles");
    window.localStorage.removeItem("token");
  }

  return (
    <Router history={history}>
      <div className="container-fluid min-h px-0">
        <NavbarComponent thisUser={user}/>
        <Switch>
          <PrivateRoute path ="/" exact component={(props) =>(
            <Home {...props} thisHistory={history} thisUser={user} />
          )}/>
          <PrivateRoute path ="/new-case"  exact component={(props) =>(
            <NewCase {...props} thisHistory={history} thisUser={user} />
          )}/>
          <PrivateRoute path ="/manage-users" component={(props) =>(
            <ManageUsers {...props} thisHistory={history} thisUser={user} />
          )}/>
          <PrivateRoute path ="/new-user"  exact component={(props) =>(
            <NewUser {...props} thisHistory={history} thisUser={user} />
          )}/>
          <PrivateRoute path="/edit-user" exact component={(props) => (
            <EditUser {...props} thisHistory={history} thisUser={user} />
          )} />
        </Switch>
        <Footer/>
      </div>
    </Router>
  );
};

export default App;
