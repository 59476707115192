import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <footer className="mt-xxl">
        <div className="container">
          <p className="body-small mb-0">Desarrollado por <b>NDS Cognitive Labs</b></p>
          <p className="body-small mb-0">© Copyright 2001-{new Date().getFullYear()} NDS Cognitive Labs - Todos los derechos reservados </p>
        </div>
      </footer>
    );
  }
}

export default Footer;