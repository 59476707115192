import React, { Component } from 'react';
import API from "../utils/api";
import ENDPOINTS from "../utils/endpoints";
import Select from 'react-select';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';

const re_email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class EditUser extends Component {
  constructor(props) {
    super(props);
    this.token = props.thisUser.token;
    this.userId = props.thisUser.sub;
    this.state = {
      given_name: "",
      family_name: "",
      email: "",
      role: "",
      og_given_name: "",
      og_family_name: "",
      og_email: "",
      og_role: "",
      error_given_name: "",
      error_family_name: "",
      error_email: "",
      error_role: "",
      user_id: "user_id",
      button_disabled: true,
      rolesOption: [],
      isLoadingRoles: true,
      updatingUser: false
    }
  }

  componentDidMount = () => {
    let { user } = this.props.location;

    if (user) {
      window.localStorage.setItem("edit_user_given_name", user.given_name);
      window.localStorage.setItem("edit_user_family_name", user.family_name);
      window.localStorage.setItem("edit_user_email", user.email);
      window.localStorage.setItem("edit_user_id", user.user_id);
    }
    else {
      user = {};
      user.given_name = window.localStorage.getItem("edit_user_given_name");
      user.family_name = window.localStorage.getItem("edit_user_family_name");
      user.email = window.localStorage.getItem("edit_user_email");
      user.user_id = window.localStorage.getItem("edit_user_id");
    }

    this.setState({
      given_name: user.given_name,
      family_name: user.family_name,
      email: user.email,
      user_id: user.user_id,
      og_given_name: user.given_name,
      og_family_name: user.family_name,
      og_email: user.email
    });

    this.getUserRole(user.user_id);
    this.getRoles();
  }

  getUserRole = (user_id) => {
    API(this.token).post(ENDPOINTS.getUserRole, {
      user_id: user_id
    })
    .then(res => {
      let role = {
        "value": res.data.role.id,
        "label": res.data.role.name
      }
      this.setState({
        role: role,
        og_role: role
      });
    })
    .catch(error => {
      console.error(error.response);
    });
  }

  getRoles = () => {
    API(this.token).get(ENDPOINTS.getRoles)
      .then(res => {
        const formated_roles = [];
        res.data.roles.forEach(function (role) {
          formated_roles.push({
            "value": role.id,
            "label": role.name
          });
        });
        this.setState({
          rolesOption: formated_roles,
          isLoadingRoles: false
        });
      })
      .catch(error => {
        console.error(error.response);
      });
  }

  validateButton = () => {
    if (this.state.given_name === this.state.og_given_name && this.state.family_name === this.state.og_family_name && this.state.email === this.state.og_email && this.state.role.value === this.state.og_role.value) {
      this.setState({
        button_disabled: true
      });
    }
    else {
      this.setState({
        button_disabled: false
      });
    }
  }

  handleGivenName = (e) => {
    this.setState({
      given_name: e.target.value
    }, () => { this.validateGivenName() });
  }

  handleFamilyName = (e) => {
    this.setState({
      family_name: e.target.value
    }, () => { this.validateFamilyName() });
  }


  handleEmail = (e) => {
    this.setState({
      email: e.target.value
    }, () => {
      this.validateEmail();
    });
  }

  handleRoles = (e) => {
    this.setState({
      role: e
    }, () => { this.validateButton(); });
  }

  validateGivenName = () => {
    if (this.state.given_name === "") {
      this.setState({
        error_given_name: "Nombre no puede estar vacío.",
        button_disabled: true
      })
    }
    else {
      this.setState({
        error_given_name: "",
      }, () => { this.validateButton(); })
    }
  }

  validateFamilyName = () => {
    if (this.state.family_name === "") {
      this.setState({
        error_family_name: "Apellido no puede estar vacío.",
        button_disabled: true
      })
    }
    else {
      this.setState({
        error_family_name: "",
      }, () => { this.validateButton(); })
    }
  }

  validateEmail = () => {
    if (this.state.email === "") {
      this.setState({
        error_email: "Correo no puede estar vacío.",
        button_disabled: true
      })
    }
    else if (!re_email.test(this.state.email)) {
      this.setState({
        error_email: "Correo no válido.",
        button_disabled: true
      })
    }
    else {
      this.setState({
        error_email: "",
      }, () => { this.validateButton(); })
    }
  }

  editUser = () => {
    this.setState({
      updatingUser: true
    });
    let body = {
      user_id: this.userId,
      user_to_update: this.state.user_id,
      bpm_id: "5efda921cbbfd901ff47acb9"
    }

    if (this.state.given_name !== this.state.og_given_name) {
      body.given_name = this.state.given_name;
      body.name = `${this.state.given_name} ${this.state.family_name}`;
    }
    if (this.state.family_name !== this.state.og_family_name) {
      body.family_name = this.state.family_name;
      body.name = `${this.state.given_name} ${this.state.family_name}`;
    }
    if (this.state.email !== this.state.og_email) {
      body.email = this.state.email;
    }
    if (this.state.role.value !== this.state.og_role.value) {
      body.role = this.state.role.value;
      body.og_role = this.state.og_role.value;
    }

    API(this.token).patch(ENDPOINTS.updateUsers, body)
      .then(res => {
        window.localStorage.removeItem("edit_user_given_name");
        window.localStorage.removeItem("edit_user_family_name");
        window.localStorage.removeItem("edit_user_email");
        window.localStorage.removeItem("edit_user_id");
        this.setState({
          updatingUser: false
        }, () => this.props.history.push("/manage-users"));
      });
  }

  render() {
    return (
      <>
        {this.state.updatingUser && <div className="creating-spinner"><Spinner variant="span" color="dark" /></div>}
        <div className="container min-h">
          <div className="row mt-xxl">
            <div className="col-12">
              <p className="h1-b mb-0">Editar usuario</p>
              <p className="body-r">Aquí podrás editar y eliminar usuarios para el uso de la plataforma</p>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-lg-4 mt-l">
              <div className="input-group">
                <input type="text" placeholder="Nombre del usuario a registrar" value={this.state.given_name} onChange={this.handleGivenName} />
                <label className="inputname">Nombre (obligatorio)</label>
                <label className="errormesage">{this.state.error_given_name}</label>
              </div>
            </div>

            <div className="col-12 col-lg-4 mt-l">
              <div className="input-group">
                <input type="text" placeholder="Apellidos del usuario a registrar" value={this.state.family_name} onChange={this.handleFamilyName} />
                <label className="inputname">Apellidos (obligatorio)</label>
                <label className="errormesage">{this.state.error_family_name}</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-lg-4 mt-l">
              <div className="input-group">
                <input type="text" placeholder="correo@ejemplo.com" value={this.state.email} onChange={this.handleEmail} />
                <label className="inputname">Correo electrónico (obligatorio)</label>
                <label className="errormesage">{this.state.error_email}</label>
              </div>
            </div>

            <div className="col-12 col-lg-4 mt-l">
              <div className={"input-group " + (this.state.isLoadingRoles && "-loading")}>
                <Select
                  options={this.state.rolesOption}
                  closeMenuOnSelect={true}
                  placeholder="Seleccionar"
                  onChange={this.handleRoles}
                  value={this.state.role}
                />
                <div className="loading-wrapper">Cargando opciones <Spinner color="dark" /></div>
                <label className="inputname">Rol (obligatorio)</label>
                <label className="errormesage">{this.state.error_role}</label>
              </div>
            </div>
          </div>

          <div className="row mt-xl">
            <div className="col-12 col-lg-4">
              <button className="button -primary w-100" disabled={this.state.button_disabled} onClick={this.editUser}>Editar usuario</button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

EditUser.propTypes = {
  thisUser: PropTypes.object
};

export default EditUser;