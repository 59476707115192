import React, { Component } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import PropTypes from 'prop-types';

class Paginator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pages: [],
            pagesCount: this.props.pages,
            currentPage: 1
        }
    }

    componentDidUpdate(nextProps) {
        if (nextProps.pages !== this.props.pages) {
          this.setState({pagesCount: this.props.pages})
          var pages = [];
          for (let i = 0; i < this.props.pages; i++) {
            pages.push(
              <PaginationItem>
                <PaginationLink onClick={e => this.handleClick(e, i)}>
                  {i + 1}
                </PaginationLink>
              </PaginationItem>
            )
          }
          this.setState({ pages: pages })
        }

        if (this.props.currentPage !== this.state.currentPage) {
          this.setState({ currentPage: nextProps.currentPage })
        }
      }

      handleClick(e, page) {
        e.preventDefault();
        if (this.props.stateName) {
          let state_validation = this.props.stateName === "active" ;
          let state = state_validation ? {activePage: page } : {inactivePage: page};
          this.props.setState(state, state_validation);
          this.setState({ currentPage: page });
        } else {
          this.props.setState({ page: page })
          this.setState({ currentPage: page });
        }
      }

      render() {
        return (
          <Pagination aria-label="Page navigation example">
            <PaginationItem disabled={this.state.currentPage <= 1}>
              <PaginationLink previous onClick={e => this.handleClick(e, this.state.currentPage-1)}/>
            </PaginationItem>

            {[...Array(this.state.pagesCount)].map((page, i) =>
              <PaginationItem active={i+1 === this.state.currentPage} key={i}>
                <PaginationLink onClick={e => this.handleClick(e, i+1)} href="#">
                  {i + 1}
                </PaginationLink>
              </PaginationItem>
            )}

            <PaginationItem disabled={this.state.currentPage >= this.state.pagesCount}>
              <PaginationLink next onClick={e => this.handleClick(e, this.state.currentPage + 1)} />
            </PaginationItem>
          </Pagination>
        );
      }
}

Paginator.propTypes = {
  pages: PropTypes.number.isRequired,
  stateName: PropTypes.string,
  currentPage: PropTypes.number.isRequired,
  setState: PropTypes.func.isRequired
};

export default Paginator;