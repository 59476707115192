import React, { Component } from 'react';
import API from "../utils/api";
import ENDPOINTS from "../utils/endpoints";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

const animatedComponents = makeAnimated();

class NewCase extends Component {
  constructor(props) {
    super(props);
    this.userId = props.thisUser.sub;
    this.token = props.thisUser.token;
    this.state = {
      formData: {
        concept: '',
        company: '',
        amount: '',
        case_type: '',
        responsable_person: {},
        frequency: '',
        comments: ''
      },
      amount: '',
      responsable_person: {},
      typeCaseOptions: [],
      responsablePersonOptions: [],
      caseFrequencyOptions: [
        {
          "value": "Semanal",
          "label": "Semanal"
        },
        {
          "value": "Quincenal",
          "label": "Quincenal"
        },
        {
          "value": "Mensual",
          "label": "Mensual"
        }
      ],
      errors: {},
      amountStartChange: false,
      conceptStartChange: false,
      companyStartChange: false,
      caseTypeStartChange: false,
      responsablePersonpeStartChange: false,
      caseFrequencyStartChange: false,
      bpmId: "5efda921cbbfd901ff47acb9",
      button_disabled: false,
      isLoadingCases: true,
      isLoadingResponsibles: false,
      creatingCase: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  apiGetAvailableCases() {
    // Get the availables case to fill the dropdown
    API(this.token).post(ENDPOINTS.getAvailableCases, {
      bpm_id: this.state.bpmId
    }).then(res => {
      const type_case_options = [];
      res.data.cases.forEach(function(available_case) {
        type_case_options.push({
          "value": available_case._id,
          "label": available_case.nombre_caso
        })
      });
      this.setState({
        typeCaseOptions: type_case_options,
        isLoadingCases: false
      });
    })
    .catch(error => {
      console.log(error.response)
    });
  }

  apiGetResponsablePersons() {
    this.setState({
      isLoadingResponsibles: true
    }, () => {
      // Get the responsable Persons  to fill the dropdown
      API(this.token).post(ENDPOINTS.getAssignees, {
        bpm_id: this.state.bpmId,
        template_id: this.state.formData.case_type.value
      }).then(res => {
        const responsable_person_options = [];
        res.data.assignees.forEach(function(person) {
          responsable_person_options.push({
            "value": person.aas_uid,
            "label": person.aas_name + " " + person.aas_lastname,
            "aas_uid": person.aas_uid,
            "aas_name": person.aas_name,
            "aas_lastname": person.aas_lastname,
            "aas_username": person.aas_username,
            "aas_type": person.aas_type
          })
        })
        this.setState({
          responsablePersonOptions: responsable_person_options,
          isLoadingResponsibles: false
        });
      })
      .catch(error => {
        console.log(error.response)
      });
    });
  }

  componentWillMount () {
    this.apiGetAvailableCases();
  }

  apiCreateCase() {
    this.setState({
      creatingCase: true
    });
    let body_params = this.state.formData;
    body_params["responsible"] = body_params.responsable_person;
    body_params["bpm_id"] = this.state.bpmId;
    body_params["user_id"] = this.userId;
    body_params["frequency"] = body_params.frequency.value;
    body_params["case_type"] = body_params.case_type.value;
    delete body_params["responsable_person"];
    delete body_params["amount_display"];
    delete body_params["responsible"]["value"];
    delete body_params["responsible"]["label"]

    API(this.token).post(ENDPOINTS.newCase, this.state.formData).then(() => {
      this.setState({
        creatingCase: false
      }, () => window.location = "/");
    })
    .catch(error => {
      console.error(error.response)
    });
  }

  handleChange(field, event) {
    // Get data from the form
    let formData = this.state.formData;

    formData[field] = (field === "case_type" || field === "responsable_person" || field === "frequency") ? event : formData[field] = event.target.value;

    this.setState({formData});

    return this.handleValidation(field)
  }

  handleSubmit(event) {
    if(this.handleValidation(null)){
      this.apiCreateCase();
    }
    event.preventDefault();
  }

  handleValidation(field) {
    let formData = this.state.formData;
    let errors = {};
    let formIsValid = true;

    //Concept
    if((!formData["concept"] && !field) || (!formData["concept"] && field === "concept") || (!formData["concept"] && this.state.conceptStartChange)){
      formIsValid = false;
      this.setState({conceptStartChange: true});
      errors["concept"] = "El concepto no puede estar vacío";
    }

    if((!formData["company"] && !field) || (!formData["company"] && field === "company") || (!formData["company"] && this.state.companyStartChange)){
      formIsValid = false;
      this.setState({companyStartChange: true});
      errors["company"] = "La empresa no puede estar vacío";
    }

    let case_type_empty = Object.keys(formData["case_type"]).length === 0;
    if((case_type_empty && !field) || (case_type_empty && field === "case_type") || (case_type_empty && this.state.caseTypeStartChange)){
      formIsValid = false;
      this.setState({ caseTypeStartChange: true});
      errors["case_type"] = "El tipo de caso no puede estar vacío";
    }

    let responsable_person_empty = Object.keys(formData["responsable_person"]).length === 0;
    if((responsable_person_empty && !field) || (responsable_person_empty && field === "responsable_person") || (responsable_person_empty && this.state.responsablePersonpeStartChange)){
      formIsValid = false;
      this.setState({ responsablePersonpeStartChange: true});
      errors["responsable_person"] = "La persona responsable no puede estar vacía";
    }

    let frequency_empty = Object.keys(formData["frequency"]).length === 0;
    if ((frequency_empty && !field) || (frequency_empty && field === "frequency") || (frequency_empty && this.state.caseFrequencyStartChange)) {
      formIsValid = false;
      this.setState({ caseFrequencyStartChange: true });
      errors["frequency"] = "La frecuencia del caso no puede estar vacía";
    }

    // Amount
    if(!this.state.formData.amount) {
      formIsValid = false;
      errors["amount_display"] = "El monto no puede estar vacío";
    }

    if(field === "case_type") {
      this.apiGetResponsablePersons();
    }

    this.setState({errors: errors});
    if (formIsValid) this.setState({button_disabled: false}); else this.setState({button_disabled: true});
    return formIsValid;
  }

  render() {
    return (
      <>
        {this.state.creatingCase && <div className="creating-spinner"><Spinner variant="span" color="dark" /></div>}
        <div className="container min-h">
          <div className="row mt-xxl">
            <div className="col-12">
              <p className="h1-b mb-0">Nuevo caso</p>
            </div>
          </div>

          <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-12 col-lg-8 mt-l">
              <div className="input-group">
                <input type="text" placeholder="Ej. Pago de mantenimiento edificio A" value={this.state.formData["concept"]} onChange={this.handleChange.bind(this, "concept")}/>
                <label className="inputname">Concepto(obligatorio)</label>
                <label className="errormesage">{this.state.errors["concept"]}</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-lg-4 mt-l">
              <div className="input-group">
                <input type="text" placeholder="Ej. Pago de mantenimiento edificio A" value={this.state.formData["company"]} onChange={this.handleChange.bind(this, "company")}/>
                <label className="inputname">Empresa(obligatorio)</label>
                <label className="errormesage">{this.state.errors["company"]}</label>
              </div>
            </div>

            <div className="col-12 col-lg-4 mt-l">
              <div className="input-group">
                <NumberFormat
                  thousandSeparator={true}
                  prefix={"$"}
                  decimalScale={2}
                  placeholder="$1000.50"
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    this.setState(prevState => {
                      let formData = Object.assign({}, prevState.formData);
                      formData.amount = floatValue;
                      return { formData };
                    });
                }}/>
                <label className="inputname">Monto(obligatorio)</label>
                <label className="errormesage">{this.state.errors["amount_display"]}</label>
              </div>
            </div>
          </div>

          <div className="row">
          <div className="col-12 col-lg-4 mt-l">
            <div className={"input-group " + (this.state.isLoadingCases && "-loading")}>
              <Select
                options={this.state.typeCaseOptions}
                closeMenuOnSelect={true}
                components={animatedComponents}
                placeholder="Seleccionar"
                onChange={this.handleChange.bind(this, "case_type")}
                value={this.state.formData.case_type}
              />
              <div className="loading-wrapper">Cargando opciones <Spinner variant="span" color="dark" /></div>
              <label className="inputname">Tipo de caso(obligatorio)</label>
              <label className="errormesage">{this.state.errors["case_type"]}</label>
            </div>
          </div>

            <div className="col-12 col-lg-4 mt-l">
              <div className={"input-group " + (this.state.isLoadingResponsibles && "-loading")}>
                <Select
                  options={this.state.responsablePersonOptions}
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  placeholder="Seleccionar"
                  onChange={this.handleChange.bind(this, "responsable_person")}
                  value={this.state.formData.responsable_person}
                />
                <div className="loading-wrapper">Cargando opciones <Spinner color="dark" /></div>
                <label className="inputname">Persona responsable(obligatorio)</label>
                <label className="errormesage">{this.state.errors["responsable_person"]}</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-lg-4 mt-l">
              <div className="input-group">
                <Select
                  options={this.state.caseFrequencyOptions}
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  placeholder="Seleccionar"
                  onChange={this.handleChange.bind(this, "frequency")}
                  value={this.state.formData.frequency}
                />
                <label className="inputname">Frecuencia del caso (obligatorio)</label>
                <label className="errormesage">{this.state.errors["frequency"]}</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-lg-8 mt-l">
              <div className="input-group">
                <textarea rows="5" placeholder="Escribe un comentario sobre este caso" value={this.state.formData["comments"]} onChange={this.handleChange.bind(this, "comments")}>
                </textarea>
                <label className="inputname">Comentarios</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-lg-4 mt-l">
              <button className="button -primary w-100" id="submit" value="Submit">Crear caso</button>
            </div>
          </div>

        </form>
        </div>
      </>
    );
  }
}

NewCase.propTypes = {
  thisUser: PropTypes.object
};

export default NewCase;