import React, { Component } from 'react';
import API from "../utils/api";
import ENDPOINTS from "../utils/endpoints";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Spinner } from 'reactstrap';

const animatedComponents = makeAnimated();

class NewUser extends Component {
  constructor(props) {
    super(props);
    this.userId = props.thisUser.sub;
    this.token = props.thisUser.token;
    this.state = {
      activeTab: '1',
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        role: {}
      },
      errors: {},
      firstNameStartChange: false,
      lastNameStartChange: false,
      emailStartChange: false,
      rolStartChange: false,
      bpmId: "5efda921cbbfd901ff47acb9",
      email_invalid: true,
      button_disabled: true,
      isLoadingRoles: true,
      creatingUser: false
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  toggle(tab) {
    this.setState({activeTab:tab})
  }

  componentDidMount() {
    // Axios /GET requests go here, when we want data ASAP!
    this.apiGetRoles();
  }

  validateButton = () => {
    if (this.state.formData.firstName === "" || this.state.formData.lastName === "" || this.state.formData.email === "" || Object.keys(this.state.formData.role).length === 0 || this.state.email_invalid) {
      this.setState({
        button_disabled: true
      });
    }
    else {
      this.setState({
        button_disabled: false
      });
    }
  }

  apiCreateUser(){
    this.setState({
      creatingUser: true
    });
    let errors = {};
    API(this.token).post(ENDPOINTS.newUser, {
      bpm_id: this.state.bpmId,
      user_id: this.userId,
      given_name: this.state.formData.firstName,
      family_name: this.state.formData.lastName,
      email: this.state.formData.email,
      name: this.state.formData.firstName + " " + this.state.formData.lastName,
      roles: [this.state.formData.role.value]
    })
    .then(() => {
      this.setState({
        creatingUser: false
      }, () => window.location = "/manage-users")
    })
    .catch(error => {
      console.log(error.response);
      errors["errorCreateUser"] = "Hubo un problema al crear el usuario, intenta de nuevo.";
      this.setState({errors: errors, creatingUser: false});
    });
  }

  apiGetRoles(){
    API(this.token).get(ENDPOINTS.getRoles)
    .then(res => {
      const formated_roles = [];
      res.data.roles.forEach(function(role){
        formated_roles.push({
          "value": role.id,
          "label": role.name
        })
      });
      this.setState({
        rolesOption: formated_roles,
        isLoadingRoles: false
      })
    })
    .catch(error => {
      console.log(error.response)
    });
  }

  handleChange(field, event) {
    // Get data from the form
    let formData = this.state.formData;
    formData[field] = field === "role" ? event : formData[field] = event.target.value;
    this.setState({formData});

    // Validate errors
    return this.handleValidation(field)
  }

  handleSubmit(event) {
    if(this.handleValidation(null)) {
      this.apiCreateUser();
    }
    event.preventDefault();
  }

  handleValidation(field) {
    let formData = this.state.formData;
    let errors = {};
    let formIsValid = true;

    //First Name
    if((!formData["firstName"] && !field) || (!formData["firstName"] && field === "firstName") || (!formData["firstName"] && this.state.firstNameStartChange)){
      formIsValid = false;
      this.setState({firstNameStartChange: true});
      errors["firstName"] = "El nombre no puede estar vacío";
    }
    //Last Name
    if((!formData["lastName"] && !field) ||  (!formData["lastName"] && field === "lastName") || (!formData["lastName"] && this.state.lastNameStartChange)) {
      formIsValid = false;
      this.setState({lastNameStartChange: true});
      errors["lastName"] = "Los apellidos no pueden estar vacíos";
    }
    //Email
    if((!formData["email"] && !field) || (!formData["email"] && field === "email") || (!formData["email"] && this.state.emailStartChange)){
      formIsValid = false;
      this.setState({emailStartChange: true});
      errors["email"] = "El correo electrónico no puede estar vacío";
    }
    if (formData["email"]){
      if(typeof formData["email"] !== "undefined"){
        let lastAtPos = formData["email"].lastIndexOf('@');
        let lastDotPos = formData["email"].lastIndexOf('.');

        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && formData["email"].indexOf('@@') === -1 && lastDotPos > 2 && (formData["email"].length - lastDotPos) > 2)) {
           formIsValid = false;
           this.setState({email_invalid: true});
           errors["email"] = "El correo electrónico no es válido";
         }
         else {
          this.setState({ email_invalid: false });
         }
      }
    }

    //Roles
    let role_empty = Object.keys(formData["role"]).length === 0;
    if((role_empty && !field) || (role_empty && field === "role") || (role_empty && this.state.rolStartChange)){
      formIsValid = false;
      this.setState({rolStartChange: true});
      errors["role"] = "El rol no puede estar vacío";
    }
    this.setState({errors: errors});

    this.validateButton();

    return formIsValid;
  }

  render() {
    return (
      <>
        {this.state.creatingUser && <div className="creating-spinner"><Spinner variant="span" color="dark" /></div>}
        <div className="container min-h">
          <div className="row mt-xxl">
            <div className="col-12">
              <p className="h1-b mb-0">Nuevo usuario</p>
              <p className="body-r">Aquí podrás dar de alta y eliminar usuarios para el uso de la plataforma</p>
            </div>
          </div>

          <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-12 col-lg-4 mt-l">
              <div className="input-group">
                <input type="text" placeholder="Nombre del usuario a registrar" value={this.state.formData["firstName"]} onChange={this.handleChange.bind(this, "firstName")}/>
                <label className="inputname">Nombre (obligatorio)</label>
                <label className="errormesage">{this.state.errors["firstName"]}</label>
              </div>
            </div>

            <div className="col-12 col-lg-4 mt-l">
              <div className="input-group">
                <input type="text" placeholder="Apellidos del usuario a registrar" value={this.state.formData["lastName"]} onChange={this.handleChange.bind(this, "lastName")}/>
                <label className="inputname">Apellidos (obligatorio)</label>
                <label className="errormesage">{this.state.errors["lastName"]}</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-lg-4 mt-l">
              <div className="input-group">
                <input type="text" placeholder="correo@ejemplo.com" value={this.state.formData["email"]} onChange={this.handleChange.bind(this, "email")}/>
                <label className="inputname">Correo electrónico (obligatorio)</label>
                <label className="errormesage">{this.state.errors["email"]}</label>
              </div>
            </div>

            <div className="col-12 col-lg-4 mt-l">
              <div className={"input-group " + (this.state.isLoadingRoles && "-loading")}>
                <Select
                  options={this.state.rolesOption}
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  placeholder="Seleccionar"
                  onChange={this.handleChange.bind(this, "role")}
                  value={this.state.role}
                />
                <div className="loading-wrapper">Cargando opciones <Spinner color="dark" /></div>
                <label className="inputname">Rol (obligatorio)</label>
                <label className="errormesage">{this.state.errors["role"]}</label>
              </div>
            </div>
          </div>
          <div className="row mt-xl">
            <div className="col-12 col-lg-4">
              <label className="errormesage"> {this.state.errors["errorCreateUser"]}</label>
              <button className="button -primary w-100" disabled={this.state.button_disabled} id="submit" value="Submit">Crear usuario</button>
            </div>
          </div>

        </form>
        </div>
      </>
    );
  }
}

export default NewUser;