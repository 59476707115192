let current_env = process.env;
const endpoints = {
    "getCasesCount": current_env.REACT_APP_GET_CASES_COUNT_EP,
    "getCases": current_env.REACT_APP_GET_CASES_EP,
    "updateCase": current_env.REACT_APP_UPDATE_CASE_EP,
    "newUser": current_env.REACT_APP_NEW_USER_EP,
    "getAvailableCases": current_env.REACT_APP_GET_AVAILABLE_CASES_EP,
    "getAssignees": current_env.REACT_APP_GET_ASSIGNEES_EP,
    "newCase": current_env.REACT_APP_NEW_CASE_EP,
    "getUsers": current_env.REACT_APP_GET_USERS_EP,
    "updateUsers": current_env.REACT_APP_UPDATE_USERS_EP,
    "getRoles": current_env.REACT_APP_GET_ROLES_EP,
    "getUserRole": current_env.REACT_APP_GET_USER_ROLE_EP,
    "deleteUser": current_env.REACT_APP_DELETE_USER_EP
};

export default endpoints