import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import PropTypes from 'prop-types';
import "moment-timezone";
import 'moment/locale/es';
import API from "../../utils/api";
import ENDPOINTS from "../../utils/endpoints";

class CaseCard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      status: props.state === "activate" ? true : false,
      animation: false
    }
  }

  updateUser(user_id, case_id, activate) {
    let body = {
      user_id: user_id,
      mongo_id: case_id,
      active: activate
    };

    API(this.props.token).patch(ENDPOINTS.updateCase, body)
      .then(() => {
        this.setState({
          animation: true
        }, () => {
          setTimeout(() => {
            this.props.getCasesCount();
            this.props.getCases(true);
            this.props.getCases(false);
            this.setState({
              "animation": false
            });
          }, 200);
        });
      }).catch(error => console.error(error) );
  }

  render() {
    return (
      <div className={`card -case ${this.state.animation ? (this.state.status ? "to-left" : "to-right") : ""}`}>
        <div className="row">
          <div className="col-12 col-lg-8">
            <p className="h3-b">{this.props.caseName}</p>
          </div>
          <div className="col-12 col-lg-4">
            <p className="body-l creationdate">
              <Moment tz="America/Mexico_City"
                      format="DD MMMM YYYY hh:mm:ss A"
                      locale="es">
                {this.props.creationDate}
              </Moment>
            </p>
          </div>
        </div>

        <div className="row">
          { this.props.lastExecution &&
            <div className="col-12 col-lg-6 d-flex align-items-center mb-s">
              <i className="fas fa-undo mr-2"></i>
              <div>
                <p className="body-r mb-0"> Última vez ejecutado:</p>
                <p className="body-r mb-0">
                  <Moment tz="America/Mexico_City"
                          format="DD MMMM YYYY hh:mm:ss A"
                          locale="es">
                    {this.props.lastExecution}
                  </Moment>
                </p>
              </div>
            </div>
          }

          { this.props.nextExecution &&
            <div className="col-12 col-lg-6 d-flex align-items-center mb-s">
              <i className="fas fa-redo mr-2"></i>
              <div>
                <p className="body-r mb-0">Próxima ejecución:</p>
                <p className="body-r mb-0">
                  <Moment tz="America/Mexico_City"
                          format="DD MMMM YYYY hh:mm:ss A"
                          locale="es">
                    {this.props.nextExecution}
                  </Moment>
                </p>
              </div>
            </div>
          }
        </div>

        <div className="row">
          <div className="col-12 col-lg-6">
            <p className="body-r"> <i className="fas fa-building mr-2"></i> {this.props.businessName}</p>
          </div>

          <div className="col-12 col-lg-6">
            <p className="body-r">
              <i className="fas fa-money-bill mr-2"></i>
              &nbsp;
              <NumberFormat value={this.props.amount}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}/>
            </p>
          </div>

          <div className="col-12 col-lg-6">
            <p className="body-r"><i className="fas fa-user mr-2"></i> {this.props.responsable}</p>
          </div>

          <div className="col-12 col-lg-6">
            <p className="body-r"><i className="fas fa-boxes mr-2"></i> {this.props.caseType}</p>
          </div>

          <div className="col-12 col-lg-6">
            <p className="body-r"><i className="fas fa-calendar-alt mr-2"></i> {this.props.frecuency}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <p className="body-r">{this.props.comments}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            {this.props.state === 'activate' &&
              <button className="button -secondary"
                      onClick={this.updateUser.bind(this,
                                                    this.props.userId,
                                                    this.props.caseId,
                                                    this.state.status)}>
                <i className="fas fa-bell"></i> Activar Caso
              </button>
            }
            {this.props.state === 'deactivate' &&
              <button className="button -secondary"
                      onClick={this.updateUser.bind(this,
                                                    this.props.userId,
                                                    this.props.caseId,
                                                    this.state.status)}>
                <i className="fas fa-bell-slash"></i> Desactivar Caso
              </button>
            }
          </div>
        </div>
      </div>
    );
  }
}

CaseCard.propTypes = {
  amount: PropTypes.any,
  creationDate: PropTypes.any,
  lastExecution: PropTypes.any,
  nextExecution: PropTypes.any,
  userId: PropTypes.string,
  caseId: PropTypes.string,
  state: PropTypes.string,
  comments: PropTypes.string,
  frecuency: PropTypes.string,
  caseType: PropTypes.string,
  responsable: PropTypes.string,
  businessName: PropTypes.string,
  caseName: PropTypes.string,
  token: PropTypes.string.isRequired,
  getCasesCount: PropTypes.func.isRequired,
  getCases: PropTypes.func.isRequired
};

export default CaseCard;