import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import PropTypes from 'prop-types';

import imagotipo from '../../svg/imagotipo.svg'

// Auth0 imports
import { useAuth0 } from "../../react-auth0-spa";

const NavbarComponent = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isAuthenticated, logout } = useAuth0();
  const roles = JSON.parse(window.localStorage.getItem("roles")) || [];
  const roleValidation = roles.includes("Super Admin") || roles.includes("Admin") || roles.includes("BPM Admin");
  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <Navbar light expand="md">
        <div className="container">
        <NavbarBrand href="/"> <img src={imagotipo} alt=""/> </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink href="/"><i className="fas fa-home"></i> Inicio</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/new-case"><i className="fas fa-folder-plus"></i> Nuevo Caso</NavLink>
            </NavItem>
            { isAuthenticated && roleValidation && (
              <NavItem>
                <NavLink href="/manage-users"><i className="fas fa-users"></i> Gestionar Usuarios</NavLink>
              </NavItem>
            )}
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                {props && props.thisUser ? props.thisUser.given_name : "Usuario"}
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={ () => logoutWithRedirect() }>
                  Cerrar sesión
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
        </div>
      </Navbar>
    </div>
  );
}

NavbarComponent.propTypes = {
  thisUser: PropTypes.object
};

export default NavbarComponent;