import React, { Component } from 'react';
import Paginator from '../Components/Paginator/Paginator';
import API from "../utils/api";
import ENDPOINTS from "../utils/endpoints";
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';

class ManageUsers extends Component {
  constructor(props) {
    super(props);
    this.token = props.thisUser.token;
    this.userId = props.thisUser.sub;
    this.state = {
      activeTab: '1',
      limit: null,
      users: [],
      page: 1,
      totalPages: null,
      modal: false,
      selectedUser: "",
      selecterId: "",
      isLoadingUsers: true
    };
  }

  _setState = (state) => {
    this.setState(state, ()=>{
      this.getUsers();
    });
  }

  componentWillMount () {
    this.getUsers();
  }

  getUsers () {
    let current_state = this.state;
    let body = {
      bpm_id: "5efda921cbbfd901ff47acb9"
    };

    if (current_state.page && current_state.totalPages) {
      body.page = current_state.page;
      body.limit = current_state.limit;
    }

    API(this.token).post(ENDPOINTS.getUsers, body)
      .then(response => {
        this.setState({
          users: response.data.users,
          page: response.data.page,
          totalPages: response.data.total_pages,
          limit: response.data.limit,
          isLoadingUsers: false
        });
      })
  }

  toggle(tab) {
    this.setState({activeTab:tab});
  }

  toggleModal  (user_name, user_id) {
    this.setState({
      modal: !this.state.modal,
      selectedUser: user_name,
      selectedId: user_id
    });
  }

  deleteUser () {
    API(this.token).delete(ENDPOINTS.deleteUser, {
        data: {
          user_id: this.userId,
          bpm_id: "5efda921cbbfd901ff47acb9",
          user_to_delete: this.state.selectedId
        }
      })
      .then(() => {
        this.setState({
          modal: !this.state.modal,
          selectedUser: "",
          selectedId: ""
        }, () => this.getUsers());
      })
      .catch(error => {
        // TODO: Add error notification
        console.error(error);
        this.setState({
          modal: !this.state.modal,
          selectedUser: "",
          selectedId: ""
        }, () => this.getUsers());
      });
  }

  render() {
    return (
        <div className="container min-h">

          <Modal centered={true} isOpen={this.state.modal} toggle={this.toggleModal.bind(this)} >
            <ModalHeader toggle={this.toggleModal.bind(this, "")}>Eliminar usuario</ModalHeader>
            <ModalBody>
              ¿Deseas eliminar a {this.state.selectedUser}?
            </ModalBody>
            <ModalFooter>
              <button className="button -primary" onClick={this.deleteUser.bind(this)}>Eliminar</button>{' '}
              <button className="button -secondary" onClick={this.toggleModal.bind(this, "")}>Cancelar</button>
            </ModalFooter>
          </Modal>

          <div className="row mt-xxl">
            <div className="col-12">
              <p className="h1-b mb-0">Administar usuarios</p>
              <p className="body-r">Aquí podrás dar de alta y eliminar usuarios para el uso de la plataforma</p>
            </div>
          </div>

          <div className="row mt-l">
            <div className="col-12 col-lg-3">
              <a href="/new-user"><button className="button -primary w-100"><i className="fas fa-user-plus"></i> Nuevo usuario</button></a>
            </div>
          </div>
          <div className="row mt-l">
            <div className="col-12">
              <table>
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Correo electrónico</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                { this.state.users.length > 0 &&
                  <tbody>
                    {this.state.users.map((user, index) => {
                        return (
                          <tr key={`row_${index}`}>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td>
                              <Link
                                to={{
                                  pathname: "/edit-user",
                                  user: user
                                }}
                              >
                                <button className="button -icon">
                                  <i className="fas fa-pencil-alt"></i>
                                </button>
                              </Link>
                              {this.userId !== user.user_id &&
                                <button onClick={this.toggleModal.bind(this, user.name, user.user_id)} className="button -icon"><i className="fas fa-trash-alt"></i></button>
                              }
                            </td>
                          </tr>
                        )
                    })}
                  </tbody>
                }
              </table>
              { this.state.isLoadingUsers &&
                <div className="loading-users">Cargando usuarios <Spinner color="dark" /></div>
              }
              { this.state.totalPages > 1 &&
                  <Paginator pages={ this.state.totalPages }
                            setState={ this._setState }
                            currentPage={ this.state.activePage }
                            getCases={ this.getCases } /> }
            </div>
          </div>
        </div>
      );
  }
}

ManageUsers.propTypes = {
  thisUser: PropTypes.object
};

export default ManageUsers;